/* ============================================
                  TOP NAVIGATION
   ============================================ */
.top-navigation {
  background-color: #fff;
  height: 6rem;
  box-shadow: $shadow-sm;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 0;
  width: 100%;
  font-size: 1.3rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  @include immaFlex($align: center);

  @include mediaQ('>=desktop-medium') {
    width: calc(100% - 7rem);
    // width: calc(100vw - 8rem);
    padding-left: 0;
    padding-right: 0;
  }

  &__nav {
    width: 100%;
    padding-right: 1rem;
    @include immaFlex($align: center);
  }

  &__nav-item {
    height: 100%;

    @include mediaQ('>=desktop-medium') {
      padding: 2rem;
    }
  }
  &__nav-item:nth-child(2) {
    margin: 0 auto;

    @include mediaQ('>=desktop-medium') {
      margin: 0 auto 0 0;
      padding: 2rem;
    }
  }

  &__hamburger {
    height: 2.5rem;
    @extend %cursor;
    @include mediaQ('>=desktop-medium') {
      display: none;
    }
  }

  &__word-plain {
    font-weight: 500;
  }

  &__word-highlight {
    @include mediaQ('>=tablet') {
      font-weight: 700;
      color: $color__primary;
    }
  }

  &__idu-version {
    color: $color__ui-light;
    background-color: $color__primary;
    padding: 0.3rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    position: relative;
    top: -1.3rem;
    right: -0.1rem;
    display: none;

    @include mediaQ('>=tablet') {
      display: inline-block;
    }
  }

  &__language-selection,
  &__profile-selection {
    display: none;
    @extend %cursor;
    @include mediaQ('>=desktop-medium') {
      display: block;
    }
  }

  &__language-selection {
    min-width: 20rem;

    ul {
      right: 21.8rem;
    }

    img {
      width: 1.6rem;
    }
  }
  &__profile-selection {
    ul {
      right: 0.9rem;

      li {
        transition: background 0.2s ease-in-out, color 0.15s ease-in-out;
      }

      li:last-child {
        width: 100%;
        margin-top: 1rem;
      }
      li:hover span {
        color: $color__primary;
      }
      li:hover p {
        color: $color__primary;
      }
      // li:hover {
      // background-color: rgba($color__background, 0.5);
      // }

      span,
      p {
        color: $color__text--dark;
        transition: background 0.2s ease-in-out, color 0.15s ease-in-out;
      }
    }

    img {
      width: 2rem;
    }
  }
  &__selection-head {
    position: relative;
    display: inline-block;
    height: 7rem;
    width: 17rem;
    transition: opacity 0.15s ease-in-out;

    @include immaFlex($align: center, $just: space-between);

    &:hover {
      opacity: 0.75;
    }

    p {
      @extend %capitalize;
      @include immaFlex($align: center);

      span {
        margin-left: 0.5rem;
      }
    }
  }

  &__selection-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: initial;
    box-shadow: $shadow-sm;
    padding: 1.3rem;
    z-index: 1;
    width: 49rem;

    li {
      float: left;
      width: 33.33%;
      height: 5rem;
      padding: 3rem;
      border: 0.1rem solid $color__background;
      transition: background 0.2s ease-in-out, color 0.15s ease-in-out;
      @extend %text-center;
      @include immaFlex($align: center, $just: center, $dir: column);

      &:hover {
        color: $color__primary;
      }
      img {
        width: 2rem;
        margin-bottom: 0.3rem;
        display: block;
        position: relative;
      }
      p {
        font-size: 1rem;
        font-weight: 500;
        @extend %capitalize;
      }
    }
  }

  &__dropdown:hover &__selection-content {
    display: block;
  }

  &__triangle {
    width: 0;
    height: 0;
    position: absolute;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid #fff;
    top: -0.8rem;
    left: 96%;
    margin-left: -0.8rem;
    box-shadow: $shadow-sm;
  }

  &__arrow {
    font-size: 1.35rem;
    border: 0.1rem solid;
    border-radius: 100%;
    margin-left: 1rem;
  }

  &__logo {
    @extend %cursor;
    @include mediaQ('>=desktop-medium') {
      display: none;
    }
    img {
      width: 4.7rem;
    }
  }
}

/* ============================================
                  SIDE NAVIGATION
   ============================================ */
.side-navigation {
  background-color: #fff;
  box-shadow: $shadow-sm;
  height: 100vh;
  position: fixed;
  z-index: 1002;
  width: 80vw;

  @include mediaQ('>=tablet') {
    width: 40vw;
  }

  &__open {
    transition: all 0.35s ease-in-out;
    transform: translateX(0);
    box-shadow: $shadow-md;

    @include mediaQ('>=desktop-medium') {
      width: 7rem;
    }
  }

  &__close {
    transition: all 0.35s ease-in-out;
    transform: translateX(-100%);
    box-shadow: $shadow-md;

    @include mediaQ('>=desktop-medium') {
      width: 7rem;
      transform: translateX(0);
    }
  }
  &__logo {
    display: none;
    @extend %text-center;
    @include mediaQ('>=desktop-medium') {
      display: inline-block;
    }

    img {
      width: 4.5rem;
    }
  }

  &__profile {
    background-color: rgba(0, 0, 0, 0.01);
    border-bottom: 0.09rem solid rgba(0, 0, 0, 0.08);
    height: 10rem;
    padding-left: 1.3rem;

    @include immaFlex($align: center);
    @include mediaQ('>=desktop-medium') {
      display: none;
    }

    figure {
      margin-right: 0.8rem;
    }
  }

  &__profile-img {
    img {
      border-radius: 50%;
      border: 0.08rem solid $color__senary;
      width: 4.3rem;
    }
  }

  &__username {
    font-size: 1.85rem;
    margin-top: -0.5rem;
    color: $color__text--dark;
  }

  &__msg {
    margin-top: -0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__body {
    height: 81vh;
    width: 100%;
    @include immaFlex($align: center);

    @include mediaQ('>=desktop-medium') {
      height: 100vh;
      width: 100%;
    }
  }

  &__nav {
    height: 81vh;
    width: 100%;

    @include immaFlex($dir: column);
    @include mediaQ('>=desktop-medium') {
      height: 100%;
    }
  }

  &__nav-item {
    color: $color__text--dark;
    padding: 2rem;
    transition: background 0.2s ease-in-out, color 0.15s ease-in-out;
    @extend %capitalize;
    @extend %cursor;
    @include immaFlex($align: center);
    @include mediaQ('>=desktop-medium') {
      padding-left: 0;
      padding-right: 0;
      @include immaFlex($align: center, $just: center);
    }

    &:hover {
      color: $color__primary;
      background-color: rgba($color__background, 0.5);
    }

    &:first-child {
      display: none;
      @include mediaQ('>=desktop-medium') {
        display: block;
        padding-top: 1.5rem;
        margin-bottom: -0.5rem;
      }
    }

    &:last-child {
      margin-top: auto;
      span:first-child {
        transform: scaleX(-1);
      }
      @include mediaQ('>=desktop') {
        margin-bottom: 4rem;
      }
    }
  }

  &__disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      color: $color__text--dark;
      span {
        cursor: not-allowed;
      }
    }
  }

  &__nav-icon {
    margin-right: 4rem;
    @include mediaQ('>=desktop-medium') {
      margin-right: 0;
    }
  }

  &__nav-text {
    font-weight: 700;
    font-size: 1.6rem;
    @include mediaQ('>=desktop-medium') {
      display: none;
    }
  }

  &__nav-active {
    // background-color: $color__background;
    color: $color__primary;
  }

  // &__nav-disabled {
  //   cursor: not-allowed;
  //   color: $color__text;
  //   background-color: $color__background;

  //   &:hover {
  //     color: $color__text;
  //     background-color: $color__background;
  //   }
  // }
}

/* ============================================
                  NAVIGATION MASK
   ============================================ */
.navigation-mask {
  z-index: 1001;
  position: fixed;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(0.2rem);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease-in-out;

  @extend %cursor;
  @include mediaQ('>=desktop-medium') {
    display: none;
  }

  &__active {
    opacity: 1;
  }

  &__deactivate {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
