@keyframes fadeIn {
  from {
    background-color: rgba($color__ui-dark, 0);
  }

  to {
    background-color: rgba($color__ui-dark, 0.65);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba($color__ui-dark, 0.65);
  }

  90% {
    background-color: rgba($color__ui-dark, 0);
    z-index: -100;
  }

  to {
    background-color: rgba($color__ui-dark, 0);
    z-index: -100;
  }
}

@keyframes drop {
  from {    
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
  }

  to {
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
  }
}

    @keyframes rise40 {
      from {
        margin-top: 40%;
      }

      40% {
        margin-top: -30%;
      }

      to {
        margin-top: -30%;
      }
    }

    @keyframes rise35 {
      from {
        margin-top: 35%;
      }

      40% {
        margin-top: -30%;
      }

      to {
        margin-top: -30%;
      }
    }

    @keyframes rise20 {
      from {
    margin-top: 20%;
  }

  40% {
    margin-top: -30%;
  }

  to {
    margin-top: -30%;
  }
}

@keyframes rise15 {
  from {
    margin-top: 15%;
  }

  40% {
    margin-top: -30%;
  }

  to {
    margin-top: -30%;
  }
}

.modal {
  animation-name: fadeIn;
  animation-duration: 2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: black;
  background-color: rgba($color__ui-dark, 0.65);
  z-index: 2000;

  &.destroy {
    animation-name: fadeOut;
    animation-duration: 1s;
  }

  &__dialogue {
    animation-name: drop;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    position: relative;
    width: 80%;
    padding: 1rem;
    background-color: white;
    border-radius: $br;

    @media(min-width: 370px) {
      width: 60%;
    }

    @media(min-width: 700px) {
      width: 50%;
    }

    @media(min-width: 1000px) {
      width: 35%;
    }

    @media(min-width: 1500px) {
      width: 25%;
    }

    &.destroy {
      animation-name: rise40;
      animation-duration: 1s;

      @media(min-width: 700px) {
        animation-name: rise35;
        animation-duration: 1s;
      }

      @media(min-width: 1000px) {
        animation-name: rise20;
        animation-duration: 1s;
      }

      @media(min-width: 1500px) {
        animation-name: rise15;
        animation-duration: 1s;
      }
    }

    &__body {
      position: relative;
      height: 80%;
      width: 100%;

      &__content {
        position: relative;
        align-content: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;

        .title {
          font-weight: 400;
          font-size: 2rem;
          color: $color__ui-dark--v4;
          padding: 2%;
  
          span {
            color: $color__primary;
          }
        }

        .account-lock {
            font-size: 2rem;
            color: $color__ui-dark--v4;
            padding: 2%;

            span {
                color: $color__primary;
            }
        }

        .password-hint {
          text-align: left;
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
      }
    }

    &__footer {
      position: relative;
      display: flex;
      width: 100%;
      height: 20%;

      &__btn {
        flex: 1;
        height: 2.8rem;
        width: 6.5rem;
        margin: 0% 10%;
        font-weight: 400;
        font-size: 1.2rem;
        border-radius: 10rem;
        @extend %capitalize;
        @extend %cursor;

        @media(min-width: 370px) {
          height: 3.8rem;
          width: 8rem;
          font-size: 1.5rem;
        }

        @media (min-width: 700px) {
          height: 3.5rem;
          width: 8rem;
        }


        @media (min-width: 1000px) {
          height: 5rem;
          width: 12rem;
          font-size: 1.7rem;
        }

        &.submit {
          background-color: $color__primary;
          color: $color__ui-light;

          &:hover {
            background-color: $color__senary;
          }
          &:disabled {
            border-radius: 10rem;
            background-color: $color__ui-dark;
          }
        }

        &.cancel {
          background-color: $color__quinary;
          color: $color__ui-light;

          &:hover {
            background-color: rgba($color__quinary, 0.7);
          }
        }
      }
    }
  }
}
