.dashboard {
  @extend %dashboard-container;

  &__intro {
    color: $color__text--dark;
    font-size: 2rem;
    margin-right: 1.3rem;
    margin-left: 1.3rem;
    font-weight: 700;
    @extend %capitalize;
  }

  &__msg {
    font-size: 1.3rem;
    margin-right: 1.3rem;
    margin-left: 1.3rem;
    display: none;

    @include mediaQ('>=tablet') {
      display: block;
      @include immaFlex($just: space-between, $align: center);
    }
  }

  &__centrix-logo {
    width: 12rem;
    box-shadow: $shadow-sm;
    border-radius: $br;
  }

  &__read-more {
    color: $color__primary;
    font-weight: 700;
    transition: opacity 0.15s ease-in-out;
    @extend %cursor;
    @extend %uppercase;

    &:hover {
      opacity: 0.75;
    }
  }

  &__tiles {
    margin: 2rem 0.55rem 30rem;
    @include immaFlex($wrap: wrap);
  }

  &__tile {
    width: 100%;
    min-height: 19rem;
    margin: 0.75rem;
    @extend %cursor;

    @include mediaQ('>=phone-large') {
      min-height: 17rem;
      flex: 1 1 29rem;
    }
  }

  &__tile-title {
    color: $color__text;
    font-size: 1.05rem;
    font-weight: 600;
    margin-bottom: -1.75rem;
    @extend %uppercase;
  }

  &__tile-header {
    color: $color__primary;
    font-size: 2.8rem;
    font-weight: 500;
    @extend %uppercase;
    @extend %text-center;
  }

  &__tile-content {
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    @include immaFlex($align: center, $just: center);
    
    canvas {
      margin-top: 3rem;
      width: 90rem !important;
    }
  }

  &__tile-no-cursor {
    cursor: default;
  }

  &__desktop {
    display: none;
    @include mediaQ('>=tablet') {
      display: block;
    }
  }

  &__disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
