/* ============================================
                  BASE STYLES
   ============================================ */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // DEFINES WHAT 1 REM IS E.G. 1REM === 10PX
}

body {
  box-sizing: border-box;
  line-height: 1.5;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color__background;
  color: $color__text;
  letter-spacing: 0.05rem;
  @extend %lato;
}

/* ============================================
                  HEADERS
   ============================================ */
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %raleway;
}
/* ============================================
                  LISTS
   ============================================ */
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ============================================
                  INPUT STYLES
   ============================================ */

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
}

input,
select {
  text-indent: 1rem;
}

option {
  color: $color__text--dark !important;
  font-style: normal !important;
}

button,
input,
input[type='text'],
optgroup,
select,
textarea,
html input[type='button'],
input[type='reset'],
input[type='submit'],
button[disabled],
html input[disabled],
input[type='checkbox'],
input[type='radio'],
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='search'],
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  border: none;
  outline: none;
  display: block;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: $br;
}

select {
  /* SVG background image */
  background-image: linear-gradient(
      45deg,
      rgba($color__ui-light, 0.5) 50%,
      rgba($color__ui-light, 0.5) 50%
    ),
    url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 1rem;
  background-position: calc(100% - 1.3rem) center;
  background-repeat: no-repeat;
  @extend %cursor;
}

/* ============================================
                  PLACEHOLDER
   ============================================ */

::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
} /* Chrome <=56, Safari < 10 */
:-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.35s;
  transition: opacity 0.35s;
} /* FF 4-18 */
::-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.35s;
  transition: opacity 0.35s;
} /* FF 19-51 */
:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: opacity 0.35s;
  transition: opacity 0.35s;
} /* IE 10+ */
::placeholder {
  opacity: 1;
  transition: opacity 0.35s;
} /* Modern Browsers */

*:focus::-webkit-input-placeholder {
  opacity: 0;
} /* Chrome <=56, Safari < 10 */
*:focus:-moz-placeholder {
  opacity: 0;
} /* FF 4-18 */
*:focus::-moz-placeholder {
  opacity: 0;
} /* FF 19-50 */
*:focus:-ms-input-placeholder {
  opacity: 0;
} /* IE 10+ */
*:focus::placeholder {
  opacity: 0;
} /* Modern Browsers */

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba($color__ui-dark, 0.5);
  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba($color__ui-dark, 0.5);
  font-style: italic;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba($color__ui-dark, 0.5);
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba($color__ui-dark, 0.5);
  font-style: italic;
}

/* ============================================
                  SELECTION
   ============================================ */

::selection {
  background-color: $color__primary;
  color: $color__ui-light;
}
