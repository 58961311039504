.alert-message {
  box-shadow: $shadow;
  background-color: $color__ui-light;
  border-radius: 0.5rem;
  min-height: 10rem;
  z-index: 110;
  right: 3.5vw;
  top: 9rem;
  position: fixed;
  width: 93vw;
  //   width: 100%;

  @include mediaQ('>=tablet') {
    width: 50rem;
    right: 1vw;
  }

  i {
    font-size: 3rem;
  }

  &__header {
    padding: 0.5rem;
  }

  &__close {
    color: red;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 10;
    @extend %cursor;
  }

  &__text {
    font-weight: bold;
  }

  &__msg-container {
    margin-left: 1.4rem;
    margin-right: 1.4rem;
  }

  &__msg-text {
    font-size: 1.3rem;
  }

  &__main {
    margin-top: 3rem 3rem 0;
    width: 100%;
    padding: 1rem;
    @include immaFlex($align: center);
    @include centerElement();
  }
  &__type-normal {
    @extend %alerts;
  }
  &__type-success {
    color: $color__primary;
    @extend %alerts;
  }
  &__type-warning {
    color: $color__quaternary;
    @extend %alerts;
  }
  &__type-error {
    color: red;
    @extend %alerts;

    @include mediaQ('>=tablet') {
      font-size: 1.6rem;
    }
  }

  &__type-warning {
    @extend %alerts;
  }
}

.alert-message-normal {
  border-left: 0.65rem solid $color__tertiary;
}
.alert-message-success {
  border-left: 0.65rem solid $color__primary;
}
.alert-message-warning {
  border-left: 0.65rem solid $color__quaternary;
}
.alert-message-error {
  border-left: 0.65rem solid red;
}

.alert-message-overlay {
  //   width: 100vw;
  //   cursor: pointer;
  //   margin-top: -3rem;
  //   position: fixed;
  //   height: 100vh;
  //   z-index: 100;
  //   background-color: rgba($color__ui-dark--v5, 0.5);
}
