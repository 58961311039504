.ocr {
  position: relative;

  // NEW
  // ===================================================================
  &__camera-toggle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    cursor: default;

    h2 {
      cursor: pointer;
    }
  }
  &__file-upload-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &__dropzone-container {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-content: center;
      flex: 1;
      margin: 1.5rem;

      h2 {
        text-align: center;
        font-weight: bolder;
      }
    }
  }

  &__camera-preview-container {
    display: flex;
    flex-flow: column nowrap;
    margin: 1rem 0;

    &__previews {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      &__preview {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: 1rem;

        h3 {
          text-align: center;
        }
      }
    }
  }

  &__document-preview {

    &__clear-button {
      text-align: right;
      position: relative;
      z-index: 2;
      margin-bottom: -1.8rem;

      span {
        color: red;
      }
    }

    img {
      max-height: 24rem;
      max-width: 28rem;
      border-radius: 1rem;

      @include mediaQ('>=phone-large') {
        max-height: 28rem;
        max-width: 32rem;
      }
    }
  }


  // OLD (can't delete as some components still use this.
  // ===================================================================

  &__form-btn {
    font-size: 1.05rem;
    font-weight: 500;
    @extend %uppercase;

    @include mediaQ('>=tablet') {
      font-size: 1.15rem;
    }
  }

  &__selfie-instruction {
    text-align: center;
    margin-top: 0.5rem;
  }

  &__selfie-instruction+div {
    bottom: 13.2rem;
  }

  &__camera-toggle {
    color: $color__primary;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    @extend %cursor;
    @extend %text-center;
  }

  &__selected-image {
    border: 0.1rem solid $color__primary;
  }

  &__image-preview {
    margin-top: 2.5rem;
    position: absolute;
    top: 41.5rem;
    left: 18rem;
    @extend %text-center;
    @include immaFlex($align: center, $just: center);

    img {
      border-radius: $br;
      width: 7rem;
      height: 7rem;
      border: 0.1rem solid $color__ui-light;
      @extend %cursor;
    }
  }

  &__image-preview-item {
    position: relative;
    margin-bottom: 1rem;
    @extend %cursor;
    @extend %text-center;

    img {
      border-radius: $br;
      width: 10rem;
    }

    span {
      position: absolute;
      top: -1.4rem;
      right: -1.4rem;
    }
  }

  &__image-preview-item-2 {
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1rem;
    @extend %text-center;

    img {
      border-radius: $br;
      width: 15rem;
      height: 10rem;

      @media (min-width: 380px) {
        width: 30rem;
        height: 20rem;
      }
    }

    span {
      position: inherit;
      top: -8.8rem;
      left: -1rem;
      color: crimson;

      @media (min-width: 380px) {
        top: -18.8rem;
        left: -1rem;
      }
    }

  }

  &__image-preview-item:not(:first-child) {
    margin-left: 1.3rem;
  }

  &__facial-matching-results {
    font-size: 1.2rem;

    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
      @include immaFlex($align: center, $just: space-between);
    }

    p {
      @include immaFlex($align: center, $just: space-between);
      padding: 0.3rem 0.5rem 0.3rem 1rem;

      span {
        display: inline-block;
        @extend %text-center;
      }

      @include mediaQ('>=tablet') {
        padding: 0.8rem 0.5rem 0.8rem 1rem;
      }
    }
  }

  &__china-card-type {
    margin: 1rem auto 0;

    // position: absolute;
    @include mediaQ('>=tablet') {
      width: 50%;
    }
  }

  &__facial-matching-item {
    @include immaFlex($align: center, $just: space-between);
  }

  &__facial-matching-item:last-child {
    margin-top: 1rem;
  }

  &__facial-matching-error,
  &__facial-matching-success {
    color: $color__ui-light;
    border-radius: 100rem;
    width: 15rem;

    @extend %text-center;
  }

  &__facial-matching-error {
    background-color: $color__quinary;
  }

  &__facial-matching-success {
    background-color: $color__primary;
  }
}