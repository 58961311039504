.map {
  svg {
    width: 75rem;
    @include mediaQ('>=desktop') {
      width: 90rem;
    }
  }

  &__svg-map {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &__legends {
    font-size: 1.3rem;
    width: 80%;
    margin: 0 auto 3rem;
    @extend %capitalize;
    @include immaFlex($align: center, $just: space-around);
  }

  &__subscribed::before,
  &__no-subscription::before,
  &__coming-soon::before {
    content: '';
    display: inline-block;
    position: relative;
    top: 0.7rem;
    right: 0.5rem;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
  }
  &__subscribed::before {
    background-color: $color__primary;
  }
  &__no-subscription::before {
    background-color: $color__ui-dark--v4;
  }
  &__coming-soon::before {
    background-color: $color__quaternary;
  }

  &__label {
    -webkit-animation: fadeinleft 0.35s ease-in-out;
    -moz-animation: fadeinleft 0.35s ease-in-out;
    -o-animation: fadeinleft 0.35s ease-in-out;
    -ms-animation: fadeinleft 0.35s ease-in-out;
    animation: fadeinleft 0.35s ease-in-out;
    overflow: hidden;
    width: 18rem;
    box-shadow: $shadow;
    border-radius: 0.5rem;
    padding: 0.8rem 1rem 0.8rem 1rem;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    font-size: 1rem;
    @extend %capitalize;
  }

  &__label-header {
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: $color__primary;
    font-weight: 500;
    @extend %lato;
  }

  &__label-data-source {
    font-weight: 400;
    color: $color__ui-dark--v2;
  }

  // COUNTIES DATA ZOO DOES NOT HAVE DATA FOR
  [data-id=''] {
    fill: #dcddde;
  }

  // COUNTRIES DATA DO HAVE DATA SOURCE FOR BUT USER DOES NOT
  :not([data-id='']) {
    fill: $color__ui-dark--v4;
  }

  // COUNTRIES COMING SOON
  [data-id='coming-soon'] {
    fill: $color__quaternary;
  }
  [data-id='coming-soon']:hover {
    fill: rgba($color__quaternary, 0.8) !important;
  }

  // DATA SOURCE THE USER HAS ACCESS TO
  .country-available {
    fill: $color__primary;
  }

  // TRIGGER HOVE IF TRUE
  .success-hover {
    // -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    // transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    cursor: pointer;
    @extend %ease-in-out;

    &:hover {
      fill: rgba($color__primary, 0.8);
    }
  }

  .fail-hover {
    // -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    // transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    cursor: pointer;
    @extend %ease-in-out;

    &:hover {
      fill: rgba($color__ui-dark--v4, 0.8);
    }
  }
}
