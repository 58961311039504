/* ============================================
                  IDU-CONTAINER
   ============================================ */
%form-container {
  margin: 0 auto 4rem;
  padding-top: 9rem;
  max-width: 97rem;
}

%dashboard-container {
  margin: 0 auto 4rem;
  padding-top: 9rem;
  // max-width: 170rem;
  max-width: 97rem;
}

%inner {
  width: 92%;
}

/* ============================================
                  ALERT
   ============================================ */
%alerts {
  font-size: 1.3rem;
  width: 15rem;
  display: inline-block;
  @include immaFlex($align: center);
  @extend %uppercase;
  @extend %text-center;
}
/* ============================================
                  CLEAR FIX
   ============================================ */
%clearfix::after {
  content: '';
  clear: both;
  display: table;
}

/* ============================================
                  FONTS
   ============================================ */
%raleway {
  font-family: 'Raleway', sans-serif;
}
%lato {
  font-family: 'Lato', sans-serif;
}
%text-center {
  text-align: center;
}
%uppercase {
  text-transform: uppercase;
}
%capitalize {
  text-transform: capitalize;
}

/* ============================================
                  TRANSITION
   ============================================ */
%ease-in-out {
  -webkit-transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* ============================================
                  PLACEHOLDER
   ============================================ */
%placeholder {
  color: rgba($color__ui-dark, 0.5);
  font-style: italic;
}

/* ============================================
                  PLACEHOLDER
   ============================================ */
%cursor {
  cursor: pointer;
}

/* ============================================
                  TEXT/BACKGROUND
   ============================================ */
%text-white {
  color: $color__ui-light;
}

%bg-white {
  background-color: #fff;
}
