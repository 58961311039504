.trans-history {
  &__filters {
    margin-bottom: 3rem;
  }

  &__filter-count {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;

    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
      // @include immaFlex($align: center, $just: center);
    }
  }

  &__filter-input {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    @include mediaQ('>=tablet') {
      @include immaFlex($align: center);
    }

    input[type='text'] {
      height: 3.5rem;
      border: 0.1rem solid rgba(#000, 0.1);
      margin-right: 1rem;
      @include mediaQ('>=tablet') {
        font-size: 1.3rem;
        height: 3.8rem;
        width: 32rem;
      }
    }

    input[type='date'] {
      height: 3.5rem;
      border: 0.1rem solid rgba(#000, 0.1);
      margin-right: 1rem;
      @include mediaQ('>=tablet') {
        font-size: 1.3rem;
        height: 3.8rem;
        width: 24.35rem;
      }
    }

    button {
      text-align: center;
      width: 15%;
      background-color: $color__secondary;
      height: 3.5rem;
      color: $color__ui-light;
      padding: 0.4rem;
      margin-left: 1rem;
      @extend %cursor;
      @include immaFlex($align: center, $just: center);

      @include mediaQ('>=tablet') {
        font-size: 1.3rem;
        height: 3.8rem;
      }
    }

    span {
      margin-right: 1rem;;
    }

    .data-list {
      width: 50%;
      margin-top: 2rem;
      align-self: flex-start;
    }
  }

  &__div {
    width: 100%;
    @include immaFlex($align: center);

    img {
      display: inline-block;
      margin-left: auto !important;
      width: 8rem !important;
      margin-left: 8rem;
      margin-right: 2rem;
      width: 15rem !important;
    }

    button {
      width: 15%;
    }
  }

  .filter {
    margin-left: auto;
  }

  &__selection {
    display: block;

    select {
      margin-top: 1rem;
      margin-bottom: 3rem;
      height: 3.5rem;
      border: 0.1rem solid rgba(#000, 0.1);

      @include mediaQ('>=tablet') {
        margin: 0 0 0;
        height: 3.8rem;
        width: 24.35rem;
      }
    }
  }

  &__transaction:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__transaction {
    font-size: 1.2rem;
    padding: 1.3rem;
    box-shadow: $shadow-md;
    position: relative;

    @include mediaQ('>=tablet') {
      padding-left: 4.5rem;
    }
  }

  &__border-success {
    border-left: 0.45rem solid $color__primary;
  }

  &__border-error {
    border-left: 0.45rem solid $color__quinary;
  }

  &__availability {
    display: none;

    @include mediaQ('>=tablet') {
      @include immaFlex($align: center);
      @include centerElement($lt: 2.5%);
    }
  }

  &__icon-success {
    color: $color__primary;
  }

  &__icon-error {
    color: $color__quinary;
  }

  &__body {
    margin-bottom: 1.5rem;
    @include mediaQ('>=tablet') {
      margin-left: 0.5rem;
    }

    p {
      white-space: normal !important;
    }
  }

  &__header {
    margin-bottom: 1.5rem;
    @include immaFlex($align: center, $just: space-between);
    h4 {
      font-size: 1.4rem;
    }
  }

  &__btn,
  &__link {
    padding: 0.8rem;
    border-radius: 100rem;
    color: $color__ui-light;
    background-color: $color__secondary;

    @include mediaQ('>=tablet') {
      width: 15rem;
      margin-left: 0.5rem;
    }
  }

  &__btn-enabled {
    @extend %cursor;
  }
  &__btn-disabled {
    cursor: not-allowed;
  }

  &__pagination {
    text-align: center;
    font-size: 1.3rem;
    @include immaFlex($align: center, $just: center);
  }

  &__pagination-opacity-off {
    opacity: 0;
  }
  &__pagination-opacity-on {
    opacity: 1;
  }

  &__pagination-items {
    cursor: default;
    padding: 1rem;
    list-style: none;
    display: inline-block;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }

  &__pagination-item {
    cursor: pointer;
    padding: 1rem;
    list-style: none;
    display: inline-block;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }

  &__arrow {
    font-size: 1.6rem;
    position: relative;
    top: 0.05rem;
  }

  &__pagination-number {
    background-color: rgba($color__primary, 0.65);
    color: $color__ui-light;
    padding-top: 0.5rem;
    font-size: 1.05rem;
    padding-bottom: 0.5rem;
  }

  &__pagination-active {
    background-color: rgba($color__primary, 1);
    color: $color__ui-light;
    padding-top: 0.5rem;
    font-size: 1.05rem;
    padding-bottom: 0.5rem;
  }

  &__pagination-navigation {
    color: rgba($color__primary, 1);
    @include immaFlex($align: center, $just: center);
  }

  &__link {
    color: $color__ui-light;
    text-decoration: none;
    display: inline-block;
    @extend %text-center;
  }
}
