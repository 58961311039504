.common-form {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  @extend %form-container;

  &__info {
    font-size: 1.3rem;
    margin-bottom: 3rem;
    @include mediaQ('>=tablet') {
      font-size: 1.6rem;
    }
  }
  &__body {
    background-color: #fff;
    padding: 3rem 1.3rem;
    box-shadow: $shadow-sm;
    border-radius: 0 0 $br $br;
    margin-bottom: 3rem;
  }

  &__margin-bottom {
    margin-bottom: 20rem;
  }

  &__box {
    box-shadow: $shadow;
    padding: 1.1rem 1.3rem;
    font-size: 1.3rem;
    transition: background 0.2s ease-in-out, color 0.15s ease-in-out;
    border-radius: $br;

    @extend %cursor;
    @include immaFlex($align: center, $just: space-between);
    @include mediaQ('>=tablet') {
      padding: 1.3rem 1.3rem;
    }

    &:hover {
      background-color: $color__primary;
      color: $color__ui-light;
    }
  }
  &__box:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__title {
    font-weight: 600;
    font-size: 1.3rem;
    @extend %uppercase;
  }

  &__arrow {
    transform: rotate(-180deg);
  }

  &__sub-title {
  }
}
