.table {
    width: 100%;
    height: calc(100% - 6rem);
    transform: translateY(6rem);

    @include mediaQ('>=desktop-medium') {
      width: calc(100% - 7rem);
      transform: translate(7rem, 6rem);
    }

    &__action-button {
        background-color: #59c16f;
        color: #f7f8fa;
        border-radius: 0.8rem;
        padding: 1rem 0;
        cursor: pointer;
        font-weight: bold;

        &:hover {
            background-color: rgba(#59c16f, 0.8);
        }
    }

    &__create-user-button:hover {
        color: #3f51b5;
    }

    /* Hacky solution to fix the position of the pagination icons. - Joel */
    button[disabled] {
       display: inline;
       width: 5rem;
    }
}

