/* ============================================
                  LOADING DOT 
   ============================================ */
.loading-backdrop {
  position: fixed;
  background: rgba(#fff, 0.5);
  z-index: 10000;
  height: 120vh;
  margin-top: -9rem;
  width: 100%;
  transition: 400ms ease;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  z-index: 9999;

  & div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $color__primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

/* ============================================
                  LOADING MULTIPLE RIPPLE 
   ============================================ */
@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

.multi-ripple {
  width: 2.6rem;
  height: 2.6rem;
  margin: 2rem;
  z-index: 20001;
  @include immaFlex($align: center, $just: center);

  div {
    // position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.3rem solid red;
    animation: 1.5s ripple infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}

/* ============================================
                  GENERAL ANIMATION
   ============================================ */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeinleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-8%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes fadeinleft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-8%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-8%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeInRightAlert {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightAlert {
  -webkit-animation: fadeInRightAlert 0.5s ease-out;
  -moz-animation: fadeInRightAlert 0.5s ease-out;
  -o-animation: fadeInRightAlert 0.5s ease-out;
  -ms-animation: fadeInRightAlert 0.5s ease-out;
  animation: fadeInRightAlert 0.5s ease-out;
}

.fadeOutLeftAlert {
  -webkit-animation: fadeOutLeft 0.5s ease-out;
  -moz-animation: fadeOutLeft 0.5s ease-out;
  -o-animation: fadeOutLeft 0.5s ease-out;
  -ms-animation: fadeOutLeft 0.5s ease-out;
  animation: fadeOutLeft 0.5s ease-out;
}
