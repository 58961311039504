.login {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 30% 27%;
  padding: 1.3rem;
  font-size: 1.4rem;
  background-image: url('../../assets/hexagons/grouped/hexagons-grouped.png'),
    linear-gradient(
      180deg,
      $color__secondary,
      $color__primary 75%,
      $color__ui-light 110%
    );

  @include immaFlex($align: center, $just: center);

  @include mediaQ('>=phone-large') {
    font-size: 1.6rem;
    background-position: center;
    background-size: cover;
  }

  &__form {
    width: 100%;
    max-width: 34.5rem;
    padding: 4rem 0 1.3rem;
    box-shadow: $shadow-sm;
    border-radius: 2rem;
    box-shadow: $shadow-md;

    @extend %text-center;
    @extend %bg-white;
  }

  &__logo {
    img {
      width: 14rem;
      margin-bottom: 2.5rem;
    }
  }

  &__language-flags {
    li {
      padding: 1.5rem;
      display: inline-block;
      position: relative;
      @extend %cursor;
    }

    li img {
      width: 2.2rem;
      @extend %cursor;
    }
  }

  &__flag-mask::after {
    content: '';
    display: inline-block;
    position: absolute;
    background-color: #fff;
    opacity: 0.4;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    left: 1.5rem;
  }

  &__password,
  &__contact,
  &__terms-and-condition,
  &__copyright {
    color: $color__secondary;
    margin-bottom: 0.4rem;
    a {
      text-decoration: none;
      color: $color__secondary;
    }
  }

  &__copyright,
  &__terms-and-condition {
    font-size: 1.2rem;
  }

  input[type='text'],
  input[type='password'] {
    width: 100%;
    border: none;
    height: 4.8rem;
    background-color: rgba($color__primary, 0.6);
    color: $color__ui-light;
    border-radius: 0;
    
    &::placeholder {
      @extend %text-white;
    }

    &:focus {
      border: 0.1rem solid $color__primary;
    }
  }

  button {
    width: 16rem;
    margin: 3rem 0;
    background-color: $color__primary;
    border: none;
    display: inline-block;
    padding: 1.4rem;
    font-weight: 700;
    border-radius: $br;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.2);

    @extend %text-white;
    @extend %cursor;
  }
}
