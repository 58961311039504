.form-group {
  vertical-align: top;
  width: 100%;
  margin-bottom: 2.5rem;
  position: relative;

  &__label {
    font-size: 1.05rem;
    font-weight: 500;
    @extend %uppercase;
    @include mediaQ('>=tablet') {
      font-size: 1.15rem;
    }
  }

  &__error-text {
    color: $color__quinary;
    position: absolute;
    font-size: 1.05rem;
    @include immaFlex($align: center);
    @include mediaQ('>=tablet') {
      font-size: 1.1rem;
    }

    span {
      font-size: 1.8rem;
      margin-right: 0.2rem;
      @include mediaQ('>=tablet') {
        font-size: 2rem;
      }
    }
  }

  textarea:disabled,
  input[type='text']:disabled {
    background: rgba(#eee, 0.5);
  }

  textarea,
  input[type='tel'],
  input[type='text'],
  input[type='date'],
  input[type='month'],
  input[type='password'],
  select {
    border: 0.1rem solid rgba(#000, 0.1);
    background-color: #fff;
    height: 3.75rem;
    font-size: 1.3rem;
    transition: border 0.2s ease-in-out;
    width: 100%;

    @include mediaQ('>=tablet') {
      height: 4.15rem;
    }

    &:focus {
      border: 0.1rem $color__primary solid;
    }
  }

  input[type='date'] {
    text-indent: 0.5rem;
  }

  input[type='month'] {
    text-indent: 0.5rem;
  }

  textarea.input-empty,
  input[type='tel'].input-empty,
  select.input-empty,
  input[type='date'].input-empty {
    color: rgba($color__ui-dark, 0.5);
    font-style: italic;
  }
  input[type='month'].input-empty {
    color: rgba($color__ui-dark, 0.5);
    font-style: italic;
  }

  textarea.input-error,
  input[type='tel'].input-error,
  input[type='text'].input-error,
  input[type='date'].input-error,
  input[type='month'].input-error,
  input[type='password'].input-error,
  select.input-error {
    border: 0.1rem $color__quinary solid;
    transition: border 0.2s ease-in-out;

    &:focus {
      border: 0.1rem $color__primary solid;
    }
  }

  textarea {
    height: 10rem;
    padding: 1.3rem;
  }
}

// CHINA PHONE INPUT FIELD
.react-tel-input .form-control {
  text-indent: 0;
}
