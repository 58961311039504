.form-overview {
  &__form-section {
    margin-bottom: 3rem;
  }
  &__form-inputs {
    font-size: 1.3rem;
    @include mediaQ('>=tablet') {
      @include immaFlex($align: center, $wrap: wrap);

      & > * {
        flex: 1 30rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  &__form-multiple-select {
    border: solid 0.1rem $color__primary;
    border-radius: 0.5rem;
    padding: 1rem;

    @include mediaQ('>=tablet') {
      @include immaFlex($align: center, $wrap: wrap);

      &>* {
        flex: 1 30rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  &__form-btn {
    margin-top: 44rem;
  }

  &__terms {
    input[type='text'] {
      @include mediaQ('>=tablet') {
        width: 50%;
      }
    }

    .form-group {
      margin-top: 2rem;
    }
  }

  &__terms,
  &__terms-label {
    font-size: 1.2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
    }
  }

  &__terms-label {
    margin-bottom: 2rem;
  }

  &__checkbox-container {
    margin-bottom: 3rem;
    margin-left: 0.75rem;
    @extend %cursor;
    @include immaFlex($just: space-between, $align: center);
    @include mediaQ('>=tablet') {
      width: 40rem;
    }

    p {
      margin-left: 0.75rem;
    }
    span {
      font-size: 1.8rem;
      padding: 0.6rem;
    }
  }

  &__checkbox-container {
    margin: 0;
  }

  &__additional-text {
    position: relative;

    p {
      bottom: 0.5rem;
      font-size: 1.3rem;
      position: absolute;
      font-style: italic;
      left: 0.1rem;
    }
  }

  &__ocr-icons {
    margin-bottom: 5.5rem;
    @include immaFlex($align: center, $just: space-around);
    @include mediaQ('>=tablet') {
      margin-bottom: 7.5rem;
    }
  }

  &__ocr-item {
    background-color: #fff;
    box-shadow: 0 0.4rem 0.6rem -0.1rem rgba(0, 0, 0, 0.2),
      0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    @include immaFlex($align: center, $just: center);
    @include mediaQ('>=tablet') {
      width: 4rem;
      height: 4rem;
    }

    p {
      position: absolute;
      top: 5.5rem;
      display: none;
      font-size: 1.2rem;
      @extend %text-center;
      @extend %uppercase;
      @include mediaQ('>=tablet') {
        display: block;
      }
    }

    span {
      font-size: 2rem;
      padding: 0.5rem;
      border-radius: 50%;

      @include mediaQ('>=tablet') {
        font-size: 2.5rem;
      }
    }
  }

  &__ocr-success,
  &__ocr-error {
    position: absolute;
    top: -1.5rem;
    right: -1.6rem;
    font-size: 2.1rem !important;
    opacity: 0.8;
  }

  &__ocr-success {
    // color: $color__primary;
  }

  &__ocr-error {
    // color: $color__quinary;
  }

  &__ocr-item-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &__ocr-item-active {
    color: $color__primary !important;
  }

  &__arrows {
    display: none;
    @include mediaQ('>=tablet') {
      display: block;
    }
  }

  // AUTO ADDRESS INPUT FIELDS
  &__auto-address {
    padding: 0 1rem;
    margin-bottom: 4rem;
  }

  &__address-option {
    font-size: 1.35rem;
    position: relative;
    top: 1.35rem;
    @extend %text-center;
  }

  &__address-switch {
    font-size: 1.5rem;
    color: $color__primary;
    font-weight: 600;
    @extend %text-center;
    @extend %cursor;
  }

  &__suggestion-item--active,
  &__suggestion-item {
    padding: 1.5rem;
    font-size: 1.3rem;
    border-radius: $br;
    background-color: rgba(#ddd, 0.2);
    @extend %cursor;
  }

  &__suggestion-item--active {
    background-color: rgba(#ddd, 0.6);
  }

  &__address-error {
    border: 0.1rem $color__quinary solid;
    transition: border 0.2s ease-in-out;

    &:focus {
      border: 0.1rem $color__primary solid;
    }
  }
}
