.data-source-selection {
  &__box {
    box-shadow: $shadow-md;
  }

  &__checkbox {
    padding: 1.3rem;
    width: 100%;
    @extend %cursor;
    @include immaFlex($align: center, $just: space-between);
    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
      padding: 1.6rem;
    }

    &:hover {
      background-color: rgba($color__text, 0.1);
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;

    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
    }
  }

  &__icon-checked,
  &__icon-unchecked,
  &__icon-disabled {
    font-size: 1.8rem;
  }

  &__icon-checked {
    color: $color__primary;
  }

  &__icon-disabled {
    color: $color__disabled;
  }

  &__selected-data {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    font-size: 1.2rem;
    margin-bottom: 5.5rem;
  }
}
