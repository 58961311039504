.admin-panel {
  width: 100%;
  margin: 0 auto;
  padding-top: 9rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;

  @include mediaQ('>=desktop-medium') {
    padding-left: 9rem;
  }

  &__title {
    color: $color__text--dark;
    font-size: 2rem;
    margin-right: 1.3rem;
    margin-left: 1.3rem;
    font-weight: 700;
    @extend %capitalize;
  }
}
