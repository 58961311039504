.search-verification {
  &__info {
    font-size: 1.3rem;
    @include mediaQ('>=tablet') {
      font-size: 1.6rem;
      @include immaFlex($align: center, $just: space-between);
    }

    p {
      margin-bottom: 1rem;
      @include immaFlex($align: center);
      @include mediaQ('>=tablet') {
        @include immaFlex($align: center, $just: space-between);
      }
    }

    span {
      cursor: default;
      margin-left: 0.5rem;
    }
  }

  &__info:last-child {
    margin-bottom: 3rem;
  }

  &__success,
  &__warning,
  &__error {
    font-weight: bold;
    @extend %uppercase;
  }
  &__success {
    color: $color__primary;
  }
  &__error {
    color: $color__quinary;
  }

  &__warning {
    color: $color__quaternary;
  }

  &__input-data {
    margin: 3rem 0;
    background-color: $color__secondary;
    font-size: 1.1rem;

    @include mediaQ('>=tablet') {
      font-size: 1.3rem;
    }
  }

  &__results {
    #find-idu-table {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    #find-idu-table td, #find-idu-table th {
      border: 1px solid #ddd;
      padding: 8px;
    }
    
    #find-idu-table tr:nth-child(even){background-color: #f2f2f2;}
    
    #find-idu-table tr:hover {background-color: #ddd;}
    
    #find-idu-table th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #1b1a1a;
      color: white;
    }

    #circle {
      display: inline-block;
      color: white;
      text-align: center;
      border: 0.5px solid rgba(31, 25, 25, 0.521);    
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 50%;
      font-size: 30px;
      margin-left: 40px;
      margin-top: 20px;
    }

    #circle-margin {
      margin-left: 150px;
    }

    #risk {
      display: flex;
      margin-top: 5px;
      font-size: 13px;
    }

    #risk-level-veryhigh {
      font-size: 13px;
      margin-left: 180px;
    }
    
    #risk-level-high {
      margin-left: 37px;
      font-size: 13px;
    }

    #risk-level-medium {
      margin-left: 42px;
      font-size: 13px;
    }

    #risk-level-unknown {
      margin-left: 40px;
      font-size: 13px;
    }

    #header {
      background-color: lightgreen;
      padding: 1rem 1.3rem;
      margin-bottom: 1.5rem;
      color: $color__ui-light;
      width: 100%;
      border-radius: $br;
      @extend %uppercase;
      @include immaFlex($align: center, $just: space-between);
    }
  }

  &__verify {
    margin-top: -0.8rem;
    margin-bottom: 3rem;
    @include immaFlex($wrap: wrap);

    & > * {
      flex: 1 28.5rem;
    }
  }

  &__verify:not(:last-child) {
    margin-bottom: -10rem;
  }

  &__col {
    div {
      margin-top: 0.8rem;
    }
    @include mediaQ('>=tablet') {
      margin: 1rem;
      div:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__verify-item {
    background-color: rgba(#000, 0.04);
    font-size: 1.3rem;
    border-radius: $br;
    padding: 0.7rem 1.3rem;
    min-height: 4rem;
    position: relative;
    z-index: 1;

    span {
      cursor: default;
      text-align: right;
    }

    a:link {
      float: right;
      width: 70%;
      text-align: right;
      word-break: break-all;
      text-decoration: underline;
      overflow-wrap: break-word;
      color: $color__text;
      @extend %cursor;
      @include mediaQ('>=tablet') {
        width: 100%;
      }
    }

    @extend %capitalize;
    @include immaFlex($align: center, $just: space-between);
    @include mediaQ('>=tablet') {
      // padding: 0.85rem 1.3rem;
    }
  }

  &__verify-items {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
  }

  &__btns {
    position: relative;
    top: -9rem;
    margin-bottom: -17rem;
  }

  &__btn {
    position: relative;
    top: -4rem;
    margin-bottom: -17rem;
  }
}
