.btn-container {
  @extend %clearfix;
  button {
    box-shadow: $shadow-sm;
  }
  button:first-child {
    float: left;
    @include mediaQ('>=tablet') {
      // margin-left: -1.3rem;
    }
  }
  button:last-child {
    float: right;
    @include mediaQ('>=tablet') {
      // margin-right: -1.3rem;
    }
  }
}

.single-btn-container {
  text-align: center;
}

.btn-under {
  position: relative;
  top: 12.85rem;
  margin-right: -1.3rem;
  margin-left: -1.3rem;
}

.btn-primary {
  background-color: $color__secondary;
  color: $color__ui-light;
  height: 4rem;
  width: 13rem;
  font-weight: 400;
  border-radius: 10rem;
  display: inline-block;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  @extend %capitalize;
  @extend %cursor;

  @include mediaQ('>=tablet') {
    width: 20rem;
    font-size: 1.5rem;
    height: 5.2rem;
  }
}

.btn-primary-green {
  background-color: $color__primary;
  color: $color__ui-light;
  height: 4rem;
  width: 13rem;
  font-weight: 400;
  border-radius: 10rem;
  display: inline-block;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  @extend %capitalize;
  @extend %cursor;

  @include mediaQ('>=tablet') {
    width: 20rem;
    font-size: 1.5rem;
    height: 5.2rem;
  }
  &:disabled {
    background-color: $color__ui-dark;
    color: $color__ui-light;
    height: 4rem;
    width: 13rem;
    font-weight: 400;
    border-radius: 10rem;
    display: inline-block;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    @extend %capitalize;
    @extend %cursor;

    @include mediaQ('>=tablet') {
      width: 20rem;
      font-size: 1.5rem;
      height: 5.2rem;
    }
  }
}

.btn-secondary {
  background-color: transparent;
  border: 0.1rem solid $color__quinary;
  height: 2.4rem;
  width: 12rem;
  border-radius: 10rem;
  position: relative;
  bottom: 0;
  font-style: italic;
  font-size: 1.2rem;
  color: $color__quinary;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba($color__quinary, 0.8);
  margin: 0 auto;
  bottom: -7rem;
  @extend %cursor;
  @extend %uppercase;

  @include mediaQ('>=tablet') {
    height: 2.8rem;
    width: 15rem;
    font-size: 1.3rem;
  }

  &:hover {
    background-color: $color__quinary;
    color: $color__ui-light;
  }
}

.btn-table {
  background-color: $color__primary;
  color: $color__ui-light;
  height: 2rem;
  width: 7rem;
  font-weight: 400;
  border-radius: 10rem;
  display: inline-block;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  @extend %capitalize;
  @extend %cursor;

  @include mediaQ('>=tablet') {
    width: 10rem;
    font-size: 1.5rem;
    height: 3.2rem;
  }

  &:hover {
    background-color: $color__senary;
  }
}

// OCR BTN
.ocr-btn:hover,
.ocr-btn:active,
.ocr-btn:focus,
.ocr-btn {
  background: transparent;
  border-radius: $br;
  color: $color__primary; 
  border: 0.1rem solid $color__primary;
  font-size: 1.3rem;
  background-color: #fff;
  margin-bottom: 2.5rem;
  outline: none !important;
  height: 3.75rem;
  font-size: 1.3rem;

  // @extend %uppercase;

  @extend %capitalize;
  @include immaFlex($align: center, $just: center);
  @include mediaQ('>=tablet') {
    height: 4.15rem;
  }
}

.fileUpload {
  position: relative;
  overflow: hidden;           
}

.fileUpload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  // height: 4.2rem;
}

.fileUpload input.file-input-disable {
  position: absolute;
  cursor: not-allowed;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
}

/*Chrome fix*/
input::-webkit-file-upload-button {
  cursor: pointer !important;
  // height: 4.2rem;
  width: 100%;
}
