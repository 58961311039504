/* ============================================
                  MEDIA QUERY
   ============================================ */
@mixin mediaQ($breakpoint) {
  /* 
   * MOBILE SMALL: 320px
   * MOBILE MEDIUM: 375px
   * MOBILE LARGE: 425px
  //  * TABLET: 768px
   * TABLET: 726px
   * LAPTOP / DESKTOP: 1024px
   * LAPTOP / DESKTOP LARGE: 1440px
   */
  @if $breakpoint == '>=phone-medium' {
    @media (min-width: 23.4375em) {
      @content;
    }
  }
  @if $breakpoint == '>=phone-large' {
    @media (min-width: 26.5625em) {
      @content;
    }
  }
  // @if $breakpoint == '>=tablet' {
  //   @media (min-width: 48em) {
  //     @content;
  //   }
  // }
  @if $breakpoint == '>=tablet' {
    @media (min-width: 45.375em) {
      @content;
    }
  }
  @if $breakpoint == '>=desktop' {
    @media (min-width: 64em) {
      @content;
    }
  }
  @if $breakpoint == '>=desktop-medium' {
    @media (min-width: 70.3125em) {
      @content;
    }
  }
  @if $breakpoint == '>=desktop-large' {
    @media (min-width: 90em) {
      @content;
    }
  }
  @if $breakpoint == '>=desktop-x-large' {
    @media (min-width: 110.3125em) {
      @content;
    }
  }
}

/* ============================================
                  CENTER ELEMENT
   ============================================ */
@mixin centerElement($tp: 50%, $lt: 50%, $tx: -50%, $ty: -50%, $pos: absolute) {
  position: $pos;
  top: $tp;
  left: $lt;
  transform: translate($tx, $ty);
}

/* ============================================
                  FLEX BOX
   ============================================ */
@mixin immaFlex(
  $dis: flex,
  $dir: null,
  $just: null,
  $align: null,
  $wrap: null,
  $flex: null
) {
  display: $dis;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
  flex-wrap: $wrap;
  flex: $flex;
}
