.usage-report {
  width: 100%;
  margin: 0 auto;
  padding-top: 9rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;

  @include mediaQ('>=desktop-medium') {
    padding-left: 9rem;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    @include mediaQ('>=desktop-large') {
      font-size: 1.45rem;
    }
  }

  &__data {
    display: inline-block; //create a BFC to remove floating
  }

  &__table {
    width: 100%;
    @include mediaQ('>=desktop-large') {
      float: left;
      width: 35%;
    }
  }

  &__graphs {
    @include immaFlex($wrap: wrap);
    @include mediaQ('>=desktop-large') {
      float: right;
      width: 65%;
    }
  }

  &__chart-tile {
    flex: 1 1 49rem;
    min-height: 10rem;
    margin-bottom: 0;
    @include mediaQ('>=desktop') {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    canvas {
      width: 100% !important;
    }
  }

  // TABLE
  table {
    font-size: 1.33rem;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5rem;
    margin: 0.5rem 0 0.75rem;
  }

  table tr {
    padding: 0.35rem;
  }
  table tr:not(:last-child) {
    border-bottom: 0.1rem solid rgba($color__secondary, 0.15);
  }

  table th,
  table td {
    padding: 1rem;
    text-align: center;
    white-space: nowrap;
    text-align: right;
    text-align: center;
  }

  table th:first-child,
  table td:first-child {
    text-align: left;
  }

  table th:not(:first-child),
  table td:not(:first-child) {
    text-align: right;
  }

  table th {
    font-size: 1.3rem;

    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-bottom: 0.1rem solid rgba($color__secondary, 0.15);
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;
      font-size: 1.33rem;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 0.1rem;
      margin: -0.1rem;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0.1rem;
    }

    table tr {
      box-shadow: $shadow;
      display: block;
      margin-bottom: 0.625rem;
    }

    table td {
      border-bottom: 0.1rem solid rgba($color__secondary, 0.15);
      display: block;
      font-size: 1.05rem;
      text-align: right;
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}
