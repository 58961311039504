/* ============================================
                  COLOURS
   ============================================ */
$color__primary: #59c16f;
$color__secondary: #5977c1;
$color__tertiary: #b92d00;
$color__quaternary: #ffae42;
$color__quinary: #f93d5c;
$color__senary: #59c1a3;

$color__background: #eef2f7;

$color__ui-light: #fafbfc;
$color__ui-light--v2: #f7f8fa;
$color__ui-light--v3: #ececed;

$color__ui-dark: #acb4b9;
$color__ui-dark--v2: #7f8a93;
$color__ui-dark--v3: #374047;
$color__ui-dark--v4: #505050;
$color__ui-dark--v5: #131315;

$color__text: #8c9aa7;
$color__text--dark: #212f48;
$color__text--light: rgba(172, 180, 185, 0.5);
$color__disabled: #e8e9eb;

/* ============================================
                  BOX SHADOWS
   ============================================ */
$shadow-xs: 0 0 0 0.1rem rgba(0, 0, 0, 0.05);
$shadow-sm: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.05);
$shadow: 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.1),
  0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 0.4rem 0.6rem -0.1rem rgba(0, 0, 0, 0.1),
  0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.06);
$shadow-lg: 0 1rem 1.5rem -0.3rem rgba(0, 0, 0, 0.1),
  0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.05);
$shadow-xl: 0 2rem 2.5rem -0.5rem rgba(0, 0, 0, 0.1),
  0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.04);
$shadow-2xl: 0 2.5rem 5rem -1.2rem rgba(0, 0, 0, 0.25);
$shadow-inner: inset 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.06);
$shadow-outline: 0 0 0 0.3rem rgba(66, 153, 225, 0.5);

// $bbs: 0 0.2rem 0.2rem hsla(0, 0%, 0%, 0.2);
// $bbs--v2: 0 0.1rem 2rem -1.3rem rgba($color__ui-dark--v5, 0.2);
// $bbs--v3: 0 -0.2rem 2.3rem -1.8rem rgba($color__ui-dark--v5, 0.45);
// $bbs--v4: 0 0.1rem 3rem -1.2rem rgba($color__ui-dark--v5, 0.2);

/* ============================================
                  BORDER RADIUS
   ============================================ */
$br: 0.5rem;
