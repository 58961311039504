.header-primary {
  background-color: rgba($color__secondary, 1);
  color: $color__ui-light;
  padding: 1.5rem 1.3rem;
  font-size: 1.1rem;
  padding-left: 1.3rem;
  border-radius: 1rem 1rem 0 0;
  @extend %uppercase;
  @include immaFlex($align: center, $just: space-between);
  @include mediaQ('>=tablet') {
    font-size: 1.3rem;
  }
}

.header-secondary {
  background-color: $color__primary;
  padding: 1rem 1.3rem;
  margin-bottom: 1.5rem;
  color: $color__ui-light;
  width: 100%;
  border-radius: $br;
  @extend %uppercase;
  @include immaFlex($align: center, $just: space-between);
}

.header-title-secondary {
  font-size: 1.3rem;
  font-weight: 500;

  @include mediaQ('>=tablet') {
    font-size: 1.45rem;
  }
}
