// TODO - SWITCH FROM SPAN TO SVG
i.icon-size,
span.icon-size {
  font-size: 2.35rem;
}

i.warning,
span.warning {
  color: $color__quaternary;
}

i.error,
span.error {
  color: $color__quinary;
}

i.success,
span.success {
  color: $color__primary;
}
i.white,
span.white {
  color: $color__ui-light;
}

.material-icons {
  @extend %cursor;
}

span.no-cursor {
  cursor: default;
}
span.not-allowed {
  cursor: not-allowed;
}
