.dropdown {
  margin-top: -1rem;
  box-shadow: $shadow;
  font-size: 1.2rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__title {
    font-weight: 400;
  }

  &__option {
    padding: 1.3rem 1.6rem;
    font-weight: 300;
    transition: background 0.15s ease-in-out;

    @extend %cursor;
    @extend %capitalize;
    @include immaFlex($align: center, $just: space-between);
    @include mediaQ('>=tablet') {
      padding: 1.6rem 1.6rem;
      font-size: 1.3rem;
    }

    &:hover {
      background-color: rgba($color__text, 0.1);
    }
  }

  &__radio {
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: $color__ui-light--v2;
          border-radius: 100%;
          border: 0.1rem solid darken($color__ui-light--v2, 10%);
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          position: relative;
          top: -0.2rem;
          margin-right: 1rem;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;

          @include mediaQ('>=phone-large') {
            width: 1.85rem;
            height: 1.85rem;
          }
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: $color__primary;
            box-shadow: inset 0 0 0 0.4rem $color__ui-light--v2;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $color__primary;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 0.4rem $color__ui-light--v2;
            border-color: darken($color__ui-light--v2, 10%);
            background: darken($color__ui-light--v2, 10%);
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
}
